body {
  overflow-x: hidden !important;
}

/**
   * Variables
   */
@import "_variables";
@import "_svg";
@import "_functions";
@import "_mixin";

/**
   * Bootstrap
   */
@import "node_modules/bootstrap/scss/bootstrap";

/**
   * Pages
   */
@import "_create";
@import "_home";
@import "_footer";
@import "_cart";
@import "_assembly";
@import "_process";
// @import "_marketing";
@import "_nav";
// @import "_designer";
@import "_priceWidget";
// @import "_orderStatus";
@import "_navbar";
// @import "_profile";
// @import "_slider";
@import "_createForm";
// @import "_membershipModal";
// @import "_ccInfo";
@import "_designModal";
// @import "_creditKey";
// @import "_roadmap";
// @import "_loader";
// @import "_banner";
@import "_progressBar.scss";
// @import "_dashboard.scss";
@import "_homepage.scss";
@import "_bluesection.scss";
@import "_customCarousel.scss";
@import "_launchyourbrandsection.scss";
@import "_model.scss";
@import "_login.scss";
// @import "_prodcalculator.scss";
// @import "_updateAndConnectShopifyModal.scss";
// @import "_videoPage.scss";
// @import "_confirmation.scss";
// @import "_transactions.scss";

.font-xl {
  font-size: 8rem;
}
.body-content {
  @include media-breakpoint-down(md) {
    max-width: 100%;
    overflow-x: hidden;
  }
}

.return.modal {
  .modal-dialog {
    max-width: 1024px;
    width: 95vw;
  }
  .image-col {
    width: 100px;
  }
}

.page-title {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0px !important;
  }
}
.edit-page-title {
  // Edit Profile
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20vw;
    margin-bottom: 15vw;
    h1 {
      font-size: 28px;
    }
  }
}

.mt-10 {
  margin-top: $spacer * 6 !important;
}

.mx-md-10 {
  @include media-breakpoint-up(md) {
    margin-right: $spacer * 6 !important;
    margin-left: $spacer * 6 !important;
  }
}

.mx-md-12 {
  @include media-breakpoint-up(md) {
    margin-right: $spacer * 7 !important;
    margin-left: $spacer * 7 !important;
  }
}

.mt-md-10 {
  @include media-breakpoint-up(md) {
    margin-top: $spacer * 6 !important;
  }
}

.ml-md-20 {
  @include media-breakpoint-up(md) {
    margin-left: $spacer * 10 !important;
  }
}
.mt-md-20 {
  @include media-breakpoint-up(md) {
    margin-top: $spacer * 10 !important;
  }
}
.home-icons {
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 10px;
  }
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
  @include media-breakpoint-down(lg) {
    width: 100% !important;
  }
}

.card-alert {
  font-size: 17px;
  color: rgb(211, 26, 26);
  font-weight: 600;
  margin-bottom: 3px;
}

.card-button {
  padding: 5px 5px;
  border: 1px solid #7c6263;
  border-radius: 7px;
  margin-top: 8px;
  align-self: center;
  color: whitesmoke;
  background-color: #7c6263;
  text-decoration: none !important;
}

.card-button:hover {
  color: #7c6263;
  background-color: white;
  text-decoration: none !important;
}

.page-body {
  padding-top: 70px;
  min-height: 56vh;
  padding-bottom: 100px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  // margin: 0 auto;

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }

  .space-around {
    .row {
      justify-content: flex-start;
      padding: 5px;
      .col.settings {
        background-color: white;
        padding: 8px;
        border-radius: 7px;
        width: 90%;
        margin-left: 1rem;
        margin-bottom: 3rem;
        .settings-title {
          font-size: 21.5px;
          margin-bottom: 0.8rem;
        }
        .users-title {
          font-size: 18.5px;
          margin-bottom: 0.8rem;
        }
        .sett-desc {
          font-size: 16px;
          margin-bottom: 1rem;
        }

        .editSub:hover {
          font-size: 17px;
          color: #7c6263;
          cursor: pointer;
        }
      }
    }
  }
}
.pro-pic {
  @include media-breakpoint-down(xs) {
    display: flex;
    justify-content: center;
    max-width: 60%;
    height: auto;
    margin: 10vw auto;
  }

  @include media-breakpoint-up(sm) {
    max-width: 60%;
    height: auto;
  }
}
.profile-col {
  display: flex;
  justify-content: center !important;
  margin-bottom: 5vw;
}
.save-padding {
  @include media-breakpoint-up(sm) {
    margin-left: 1.5vw;
  }
}
.table-display {
  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 70vw;
    margin: 0% auto;
  }
}

.close-button {
  position: absolute;
  right: 0;
  font-size: 15;
  top: 0;
  background-color: black;
  color: white;
  border-radius: 50%;
  border: 1px solid white;
}

.my-custom-delete-btn {
  background-color: white;
  border: 1px solid #7c6263;
  width: 80%;
}

.my-custom-delete-btn:hover {
  background-color: #7c6263;
  border: 1px solid white;
  color: white;
  width: 80%;
}

/**
 label
*/
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $primary !important;
}

/**
 * SECTION
 */
.section {
  @include make-container();

  &--row {
    @include make-row();
  }

  &--title {
    @include make-col-ready();
    @include make-col(12, 12);
    text-align: center;

    color: $dark;
    opacity: 0.5;

    h3 {
      font-size: 1.125rem;
    }
  }

  &__breathe {
    margin-top: $spacer * 15;

    @include media-breakpoint-down(sm) {
      margin-top: $spacer * 10;
    }
  }

  &__island {
    margin: ($spacer * 5) auto;
  }
}

label.form-label.file-upload {
  width: 100%;
  height: 250px;
  background-color: $gray;
  cursor: pointer;
  opacity: 0.45;
  input {
    display: none;
  }
  p {
    text-align: center;
    span {
      text-decoration: underline;
    }
  }
}

.length-select {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    text-align: center;
    width: 48% !important;
    margin-bottom: 10px;
  }
}

body.gradient {
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(174, 169, 162, 0.1) 50%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 50%,
    rgba(174, 169, 162, 0.1) 50%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 60%,
    rgba(174, 169, 162, 0.05) 40%
  );
  @include media-breakpoint-down(sm) {
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 50%,
      rgba(174, 169, 162, 0.1) 50%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 50%,
      rgba(174, 169, 162, 0.1) 50%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 16%,
      rgba(174, 169, 162, 0.05) 7%
    );
  }
}

button.continue {
  flex-grow: 1;
}

.option {
  margin-bottom: 10px;
  .row {
    align-content: center;
    align-items: center;
  }
  .col.picture {
    width: 50%;
    img {
      width: 100%;
      height: 75px;
    }
  }
}

.catalog-state-text {
  color: #7c6263;
  cursor: pointer;
}

.catalog-state-text:hover {
  text-decoration: underline;
}

.circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  .option:hover & {
    border-color: white;
  }
}

.col.space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
/**
 * btn-group-separate
 *
 * Used for overriding btn-group styling
 */

.btn-group.btn-group-separate {
  display: inline;
}

.btn-group.btn-group-separate > .btn:not(.dropdown-toggle) {
  border-radius: 0.25em;
}

.btn-group.btn-group-separate > .btn {
  margin: 0.25em;
}

.loader {
  text-align: center;
  i {
    margin-bottom: 10px;
  }
  &.middle-page {
    position: absolute;
    @include media-breakpoint-down(sm) {
      top: 15vh;
      right: 40vw;
    }
    top: 30%;
    right: 40%;
    i {
      font-size: 6em;
    }
  }
}
.invoice-inside {
  display: flex;
  justify-content: center;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.img-placeholder {
  height: 1.3em;
  width: 1em;
  background-color: grey;
}

.home-cta {
  font-size: 32px;
  @include media-breakpoint-up(md) {
    font-size: 48px;
  }
}

.sm-order-last {
  @include media-breakpoint-down(md) {
    order: 1;
  }
}
