.mainContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "\A";
    position: absolute;
    background: #7c6262;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40%;
    z-index: 0;
  }
  .formContainer {
    position: absolute;
    z-index: 1;
    top: 15%;
    width: 70%;
    height: 70%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: row;
    .leftForm {
      width: 35%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      img {
        align-self: center;
        &:nth-child(1) {
          width: 150px;
          height: 150px;
          margin-bottom: 20px;
        }
      }
    }
    .rightForm {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      p {
        font-size: 17px;
        text-align: center;
        font-weight: 700;
        margin-top: -100px;
        margin-bottom: 100px;
      }
      .inputGroup {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .inputField {
          padding: 10px;
          border: none;
          border-radius: 4px;
          color: #000;
          width: 400px;
          background-color: transparent;
          outline: 2px solid #7c6262;
          margin-bottom: 35px;
          &:focus + .field_label {
            transform: translate(0, -30px) scale(0.8);
            color: #000;
            font-size: 14px;
          }
          &:valid + .field_label {
            transform: translate(0, -30px) scale(0.8);
            color: #000;
            font-size: 14px;
          }
          &:is(:focus, :valid) {
            outline-color: #7c6262;
          }
          &:focus + .field_label {
            transform: translate(-10px, -70px) scale(0.8);
          }
        }
        .field_label {
          position: absolute;
          top: 50%;
          left: 29%;
          color: #000;
          transform: translate(10px, 10px);
          transform-origin: left;
          transition: transform 0.25s;
          font-size: 17px;
        }
        .field_label_fixed {
          position: absolute;
          top: 50%;
          left: 29%;
          color: #000;
          transform: translate(-10px, -70px) scale(0.8);
          transform-origin: left;
          transition: transform 0.25s;
          font-size: 17px;
        }
        .error {
          width: 400px;
          color: #c30000;
          margin-bottom: 25px;
          margin-top: -35px;
          text-align: start;
          font-size: 14px;
          font-weight: 200;
        }
      }
      button {
        background-color: #7c6262;
        color: #fff;
        width: 400px;
        font-weight: 600;
        margin: 0 auto;
        outline: none;
        text-decoration: none;
        border: 1px solid #7c6262;
        &:hover {
          background-color: transparent;
          border: 1px solid #7c6262;
          color: #7c6262;
        }
        &:focus {
          outline: 0;
        }
      }
      .error {
        color: #c30000;
        margin-bottom: 25px;
        text-align: center;
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
}
//laptop
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .mainContainer {
    &::after {
      width: 40%;
    }
    .formContainer {
      width: 85%;
      .leftForm {
        width: 70%;
        margin-bottom: 100px;
        img {
          align-self: center;
          &:nth-child(1) {
            width: 150px;
            height: 150px;
            margin-top: 100px;
          }
          &:nth-child(2) {
            width: 300px;
            margin-bottom: 40px;
          }
        }
      }
      .rightForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
          font-size: 18px;
          margin-top: 0px;
          margin-bottom: 50px;
        }
        .inputGroup {
          .inputField {
            width: 70%;
            margin-top: 0px;
            &:valid + .field_label {
              transform: translate(0, -30px) scale(0.8);
              color: #000;
              font-size: 14px;
            }
            &:is(:focus, :valid) {
              outline-color: #7c6262;
            }
            &:focus + .field_label {
              transform: translate(-5px, -70px) scale(0.8);
            }
            &:nth-child(2) {
              margin-bottom: 50px;
            }
          }
          .field_label {
            top: 55%;
            left: 18%;
            transform: translate(10px, 10px);
          }
          .field_label_fixed {
            top: 25%;
            left: 18%;
            transform: translate(-5px, -45px) scale(0.8);
          }
          .error {
            width: 70%;
            text-align: start;
          }
        }
        button {
          width: 70%;
          margin-top: 40px;
        }
      }
    }
  }
}
//tablet
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mainContainer {
    &::after {
      width: 100%;
      height: 50%;
    }
    .formContainer {
      width: 100%;
      height: 100%;
      flex-direction: column;
      .leftForm {
        width: 100%;
        margin-bottom: 250px;
        img {
          align-self: center;
          &:nth-child(1) {
            width: 150px;
            height: 150px;
            margin-top: 100px;
          }
          &:nth-child(2) {
            width: 300px;
            margin-bottom: 40px;
          }
        }
      }
      .rightForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
          font-size: 18px;
        }
        .inputGroup {
          .inputField {
            width: 70%;
            margin-top: 0px;
            &:valid + .field_label {
              transform: translate(0, -30px) scale(0.8);
              color: #000;
              font-size: 14px;
            }
            &:is(:focus, :valid) {
              outline-color: #7c6262;
            }
            &:focus + .field_label {
              transform: translate(-5px, -70px) scale(0.8);
            }
            &:nth-child(2) {
              margin-bottom: 50px;
            }
          }
          .field_label {
            top: 55%;
            left: 18%;
            transform: translate(10px, 10px);
          }
          .field_label_fixed {
            top: 25%;
            left: 18%;
            transform: translate(-5px, -45px) scale(0.8);
          }
          .error {
            width: 70%;
            text-align: start;
          }
        }
        button {
          width: 70%;
          margin-top: 40px;
        }
      }
    }
  }
}
//mobile
@media screen and (max-width: 767px) {
  .mainContainer {
    &::after {
      width: 100%;
      height: 50%;
    }
    .formContainer {
      width: 100%;
      height: 100%;
      flex-direction: column;
      .leftForm {
        width: 100%;
        margin-bottom: 80px;
        img {
          align-self: center;
          &:nth-child(1) {
            width: 150px;
            height: 150px;
            margin-top: 50px;
          }
          &:nth-child(2) {
            width: 250px;
            margin-bottom: 40px;
          }
        }
      }
      .rightForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        p {
          margin-top: 1px;
          margin-bottom: 40px;
        }
        .inputGroup {
          .inputField {
            width: 70%;
            margin-top: 0px;
            &:valid + .field_label {
              transform: translate(0, -30px) scale(0.8);
              color: #000;
              font-size: 14px;
            }
            &:is(:focus, :valid) {
              outline-color: #7c6262;
            }
            &:focus + .field_label {
              transform: translate(-5px, -70px) scale(0.8);
            }
            &:nth-child(2) {
              margin-bottom: 50px;
            }
          }
          .field_label {
            top: 55%;
            left: 18%;
            transform: translate(10px, 10px);
          }
          .field_label_fixed {
            top: 25%;
            left: 18%;
            transform: translate(-5px, -45px) scale(0.8);
          }
          .error {
            width: 70%;
            text-align: start;
          }
        }
        button {
          width: 70%;
          margin-top: 40px;
        }
      }
    }
  }
}
