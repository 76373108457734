.renderContainer {
  margin-bottom: 50px;
  width: 100%;
  .renderColumn {
    flex-direction: column;
    width: 85vw;
    @include media-breakpoint-up(md) {
      width: 70%;
      justify-content: space-around;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      .step.holder {
        justify-content: space-around;
      }
      // background: rgba(255,255,255,0);
      // background: -moz-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(174,169,162,.1) 50%);
      // background: -webkit-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(174,169,162,.1) 50%);
      // background: linear-gradient(to bottom, rgba(255,255,255,0) 60%,rgba(174,169,162,.05) 40%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#aea9a2',GradientType=0 );
    }
    padding: 0;
    height: fit-content;
    align-items: center;
    display: flex;

    .price-holder {
      width: 100%;
      margin-top: 10px;
      & > .col {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .deliveryDate {
        width: 20%;
        text-align: center;
      }
      .price-widget {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: white;
        align-items: center;
        justify-content: space-around;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        padding: 10px 0;
        width: fit-content;
        &.row {
          & > .col {
            flex-basis: unset;
            flex-grow: unset;
            max-width: unset;
            width: unset;
          }
        }

        .price-unit {
          font-size: 20px;
          font-weight: 400;
        }
        @include media-breakpoint-up(md) {
          padding: 0;
          min-width: fit-content;
          // width: 45%;
          // margin-left: 10%;
          // margin-top: -21%;
          margin-bottom: 30px;
          &.row {
            & > .col {
              margin: 45px 0;
            }
            .form-group {
              margin-bottom: 0;
            }
          }
        }

        .main-calc {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .revenue,
          .profit {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            font-size: 19px;
            margin-bottom: 8px;
          }
        }

        .switches {
          margin-bottom: 2rem;
          .toggle-switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 25px;
            margin-left: 50px;
          }
          .toggle-switch input[type="checkbox"] {
            display: none;
          }
          .toggle-switch .switch {
            position: absolute;
            cursor: pointer;
            background-color: #ccc;
            border-radius: 25px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 50px;
            transition: background-color 0.2s ease;
          }
          .toggle-switch .switch::before {
            position: absolute;
            content: "";
            left: 2px;
            top: 2px;
            width: 21px;
            height: 21px;
            background-color: #aaa;
            border-radius: 50%;
            transition: transform 0.3s ease;
          }
          .toggle-switch input[type="checkbox"]:checked + .switch::before {
            transform: translateX(25px);
            background-color: #aaa;
          }
          .toggle-switch input[type="checkbox"]:checked + .switch {
            background-color: #7c6262;
          }
        }
      }
    }
  }
  .step-container-all {
    width: fit-content;
    padding: 0;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .no-arrow::after {
      content: none;
    }

    .material-toggle:hover {
      background-color: #866d6d;
    }

    .action-buttons {
      .custom-text-and-font,
      .upload-image,
      .choose-from-library {
        @include primaryGildformBtn;
      }
    }

    .continue,
    .gildform-store-btn,
    .connect-shopify-btn {
      @include primaryGildformBtn;
    }

    .gildform-store-btn {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .link-skip {
      color: #705959;
      font-weight: 400;
    }

    .back-btn {
      background-color: white;
      color: #7c6262;
      border-color: #7c6262;
    }

    .back-btn:hover {
      color: #705959;
      border-color: #705959;
      font-weight: 500;
    }

    .spread-title {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .spread-first {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.02em;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .btn-lib {
        background-color: #7c6262;
        color: white;
        border-color: #7c6262;
      }

      .btn-lib:hover {
        background-color: #866d6d;
      }

      button {
        padding: 0.5rem;
        font-size: 0.95em;
        margin-bottom: 6px;
      }

      .marketplace-library {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    @media only screen and (max-width: 1370px) {
      .spread-title {
        .spread-first {
          font-size: 0.95em;
          font-weight: 400;
          margin-bottom: 10px;
        }
        button {
          padding: 0.2rem;
          font-size: 0.85em;
          margin-bottom: 6px;
        }
      }
    }
  }
  & > .col {
    position: relative;
    flex-basis: unset;
    flex-grow: unset;
    max-width: unset;
  }
  & > .col.holder {
    & > .row {
      width: 100%;
      flex-wrap: nowrap;
      & > .cards {
        width: 100%;
        @include media-breakpoint-up(md) {
          width: 91%;
        }
      }
      & > .steps-number {
        @include media-breakpoint-up(md) {
          width: 9%;
        }
      }
    }
  }

  .step {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 30%;
    }
    h3 {
      span {
        background-color: $primary;
        color: white;
        padding: 0px 0.5em;
        margin-right: 10%;
      }
    }
    .step-number {
      div {
        margin-bottom: 10px;
        button {
          font-size: 1.2em;
        }
      }
    }
    .spread-title {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .spread-first {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.02em;
        font-weight: 400;
        margin-bottom: 10px;
      }
      button {
        padding: 0.5rem;
        font-size: 0.95em;
        margin-bottom: 6px;
      }

      .marketplace-library {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    @media only screen and (max-width: 1370px) {
      .spread-title {
        .spread-first {
          font-size: 0.95em;
          font-weight: 600;
          margin-bottom: 10px;
        }
        button {
          padding: 0.2rem;
          font-size: 0.85em;
          margin-bottom: 6px;
        }
      }
    }

    .row {
      .form-group {
        width: 100%;
        button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.dropdown-toggle::after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border: none;
            background-image: url(../assets/images/dropdown.svg);
            width: 14px;
            height: 8px;
          }
        }
        .show {
          width: 100%;
        }
      }
    }

    .cards {
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      flex-grow: 11;
      .sect {
        margin: 15px 0;
        @include media-breakpoint-down(sm) {
          flex-direction: row;
        }
      }
      .option {
        &:hover {
          .circle {
            border-color: black;
          }
        }
        .circle {
          border-color: $secondary;
        }
      }

      img {
        height: 75%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    width: 100vw;
    margin: 0;
    // margin-left: -15px;
    .row {
      .spread-title {
        margin-bottom: 25px;
      }
      margin: 0 0px;
      flex-direction: column;
      .container {
        width: 100%;
      }
      .steps-number {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 50px 0;
      }
    }
  }
  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
  .render {
    width: 100%;
    height: 75%;
    @include media-breakpoint-up(md) {
      height: 100%;
    }
    &:focus {
      outline: transparent;
    }
    // background: rgba(255,255,255,0);
    // background: -moz-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(174,169,162,.1) 50%);
    // background: -webkit-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(174,169,162,.1) 50%);
    // background: linear-gradient(to bottom, rgba(255,255,255,0) 60%,rgba(174,169,162,.05) 40%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#aea9a2',GradientType=0 );
    @include media-breakpoint-up(md) {
      // height: 100vh;
      // position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.modal {
  .modal-content {
    .modal-body {
      .modal-store {
        min-width: 500px;

        .gild-title-product {
          font-size: 19.3px;
        }

        .gildform-item {
          padding: 10px;
          display: flex;
          flex-direction: row;
          border-top: 1px solid #cdcdcd;
          border-bottom: 1px solid #cdcdcd;

          .gild-spec {
            display: flex;
            flex-direction: column;
            font-size: 14.8px;
            margin-right: 8.5px;

            .gild-spec-item {
              font-weight: bold;
            }
          }

          .price {
            margin-left: 10px;
            font-size: 15.3px;
          }
        }

        .gild-to-add-item {
          margin-left: 20px;
          width: 90%;
          margin-bottom: 15px;

          .upload-image-text {
            display: flex;
            flex-direction: column;
            justify-content: start;

            .choose-title {
              font-size: 18px;
              text-align: center;
            }

            .choose-image-type {
              display: flex;
              flex-direction: column;
              justify-content: start;

              .upload-img {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .img-text {
                  margin-left: 5px;
                  margin-top: 4px;
                }
                input[type="checkbox"] {
                  cursor: pointer;
                  border-radius: 3px;
                  width: 15px;
                  height: 15px;
                  margin-right: 10px;
                  margin-top: 7px;
                }

                input[type="checkbox"]:after {
                  content: " ";
                  background-color: #7c6262;
                  display: inline-block;
                  visibility: visible;
                  padding: 0px 2px;
                  border-radius: 3px;
                }

                input[type="checkbox"]:checked::after {
                  content: "\2714";
                  color: whitesmoke;
                  width: 18px;
                  height: 18px;
                  margin-right: 20px;
                }
              }
            }

            .img-to-upload {
              margin-top: 10px;
              margin-bottom: 5px;
              padding: 10px;
            }

            .use-image {
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              margin-bottom: 5px;

              .img-show {
                align-items: center;
                text-align: center;
              }

              .img-show img {
                box-shadow: 3px 3px 1px #ccc;
              }
            }
            .inputs {
              .input-gild-img {
                max-height: 180px;
                overflow-y: scroll;
                max-width: 600px;

                .gild-img-name {
                  color: white;
                  background-color: #7c6262;
                  padding: 5px;
                  border-radius: 5px;
                  margin-right: 5px;
                  margin-top: 5px;

                  .image-name {
                    word-break: break-all;
                  }

                  .del-tag-icon {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .gild-text-inputs {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .gild-txt-inp {
            width: 48%;
            height: 30px;
            padding: 5px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
              rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            border: none;
          }
          .gild-txt-inp:focus {
            outline: none;
          }
        }

        .gild-inputs-price {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom: 20px;
          .gild-txt-inp {
            width: 48%;
            height: 30px;
            padding: 5px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
              rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            border: none;
          }

          .gild-txt-inp:focus {
            outline: none;
          }

          .gild-revenue-sc {
            color: rgb(13, 97, 13);
            font-size: 15.9px;
            margin-left: 15px;
            border-bottom: 1px solid #bdbdbd;
          }

          .gild-revenue-low {
            color: rgb(167, 6, 6);
            font-size: 15.9px;
            margin-left: 15px;
            border-bottom: 1px solid #bdbdbd;
          }
        }
      }

      .modal-store-btm {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
      }
    }

    .modal-footer {
      .footer-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .addItem-btn,
          .checkOut-btn {
            font-size: 15px;
            margin-left: 4px;
            padding: 5px;
            @include primaryGildformBtn;
          }

          .lib-btn {
            @include primaryGildformBtn;
          }
        }
      }

      .primary-buttons-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .addItem-btn,
        .checkOut-btn {
          @include primaryGildformBtn;
        }
      }
    }
  }
}
