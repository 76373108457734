footer {
  width: 100%;
  margin-left: 0 !important;
  background-color: #f8f5f0;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  margin-top: auto;
  &.bottom {
    position: absolute;
    top: 100%;
    @include media-breakpoint-up(md) {
      top: unset;
      bottom: -5vh;
    }
  }
  //here starts the new footers css
  .container {
    .logo-description {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .gildform-logo {
          width: 50px;
          height: 50px;
        }
        .gildform-logo-text {
          width: 300px;
        }
      }
      .gildform-description {
        font-weight: normal;
        text-align: center;
        span {
          font-size: 17px;
        }
      }
    }
    .links {
      margin-bottom: 50px;
      .single-link {
        padding: 20px 20px 5px 0;
        border-bottom: 1px solid rgba(35, 31, 32, 0.1);
        .link-click {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .link-title {
            font-size: 20px;
            font-weight: 500;
          }
          span {
            color: #231f20cc;
          }
          i {
            color: #231f20cc;
          }
        }
      }
      .link-content {
        display: flex;
        flex-direction: column;
        padding: 20px 50px 0 50px;
        a {
          text-decoration: none;
          color: #7c6161;
          i {
            margin-right: 20px;
          }
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .date {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin-left: 5px;
      color: #b07807;
    }
  }
}

@media screen and(min-width: 768px) {
  footer {
    .container {
      .logo-description {
        width: 100%;
      }
    }
  }
  hr {
    width: 85%;
  }
}

@media screen and (min-width: 992px) {
  footer {
    hr {
      width: 70%;
    }
    .container {
      display: flex;
      .logo-description {
        align-items: flex-start;
        width: 50%;
        .gildform-description {
          text-align: left;
          font-size: 20px;
          width: 80%;
          padding-left: 20px;
        }
      }
      .links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .single-link {
          border-bottom: unset;
          .link-click {
            span {
              font-size: 20px;
              font-weight: bold;
            }
            i {
              display: none;
            }
          }
          .collapse {
            display: unset !important;
            .link-content {
              padding: 30px 0 0 0;
              a {
                padding: 10px 0;
              }
            }
          }
        }
      }
    }
  }
}
