div#my-row-tour {
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #675151;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #675151;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #675151;
}

.read-more-text:hover {
  text-decoration: underline;
}
