.tab-content {
  padding: 10px 5px 0 8px;
  .pr-tab {
    background-color: #353333;
  }

  .no-prj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .no-prj,
  .no-completed {
    padding: 10px;
    font-size: 20px;
    color: #7c6262;
    font-weight: 500;
  }

  .design-content {
    padding-top: 10px;
    .cards-list {
      height: 480px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(4, 170px);
      grid-template-rows: 1fr;
      grid-column-gap: 5px;
      grid-row-gap: 20px;
    }

    .card {
      margin: 5px auto;
      width: 170px;
      border-radius: 15px;
      cursor: pointer;
      transition: 0.4s;
      display: flex;
      justify-content: center;
    }

    .card .card_image img {
      width: 168px;
      max-width: 100%;
      max-height: 130px;
      border-radius: 15px;
    }

    .disable-card {
      pointer-events: none;
      opacity: 0.4;
    }

    .card .card_title {
      text-align: center;
      border-radius: 0px 0px 15px 15px;
      font-family: sans-serif;
      font-size: 15px;
      margin-top: 10px;
    }

    .card:hover {
      transform: scale(0.9, 0.9);
    }

    .card-type {
      margin-right: 4px;
      margin-top: 40px;
      line-height: 45px;
      min-width: 50px;
      // height: 30px;
      border-radius: 8px;
      padding: 0.45rem;
      cursor: pointer;
      color: #7c6262;
      border: 1px solid #7c6262;
      margin-bottom: 0.3rem;
    }

    .card-type:hover {
      background-color: #7c6262;
      color: whitesmoke;
      transform: scale(0.9, 0.9);
    }

    .selected-type {
      background-color: #7c6262;
      color: whitesmoke;
    }

    .title-white {
      color: white;
    }

    .title-black {
      color: black;
    }

    .selected-card {
      border: 2px solid #333333;
    }

    .switcher {
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        outline: none;
      }
      .switch input {
        position: absolute;
        top: -99999px;
        left: -99999px;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
      input:checked + .slider {
        background-color: #7c6262;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px #7c6262;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  }

  .marketplace-lib-main {
    padding: 10px 15px 10px 12px;

    .mdl-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 24px;
      color: #7c6262;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .filter-designs {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 10px;

      .filter-title {
        margin-top: 5px;
        margin-right: 7px;
        font-size: 17px;
      }
    }

    .models-list {
      min-height: 280px;
      max-height: 450px;
      overflow-x: hidden;
      display: grid;
      grid-template-columns: repeat(4, 160px);
      grid-template-rows: 1fr;
      grid-column-gap: 5px;
      grid-row-gap: 20px;

      .model-card {
        margin: 5px auto;
        width: 160px;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid #cdcdcd;

        .model-title {
          width: 100%;
          text-align: center;
          margin-top: 1rem;
          font-family: sans-serif;
          color: black;
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 15px;
        }

        .card_image {
          text-align: center;
          margin-top: 0.8rem;
        }
      }

      .selected-card {
        border: 2px solid #353333;
      }

      .model-card .card_image img {
        width: 140px;
        max-width: 100%;
        max-height: 110px;
        border-radius: 15px;
      }

      .model-card:hover {
        transform: scale(0.96, 0.96);
      }
    }
  }
}

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}
