@mixin svg-background(
  $color: $primary,
  $width: 100%,
  $height: 100%,
  $x: 0,
  $y: 0
) {
  background-image: url(svg-encode(
    svg-factory($color, $width, $height, $x, $y)
  ));
  background-size: contain;
  background-color: unset;
}

@mixin primaryGildformBtn {
  color: white;
  background-color: #7c6262;
  border-color: #7c6262;
  &:hover {
    background-color: #967a7a;
    border-color: #967a7a;
  }
}
