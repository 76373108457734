.icons-container {
  margin-top: 50px;
  .icon-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    .icon-image {
      padding: 20px;
    }
    .icon-title {
      text-align: center;
      padding: 20px;
      span {
        font-size: 20px;
        font-family: "Spartan", sans-serif;
        font-weight: bold;
      }
    }
    .icon-text {
      padding: 20px;
      text-align: center;
      span {
        font-size: 20px;
        color: #231f20b2;
      }
    }
  }
}

@media screen and(min-width: 992px) {
  .icons-container {
    display: flex;
    flex-direction: row;
    .icon-card {
      width: 100%;
      padding: 20px;
      .icon-text {
        padding: 20px 50px;
      }
    }
  }
}
