.dimensions-error {
  width: 80%;
  margin: 0 auto;
  color: red;
  padding: 10px 0;
  text-align: justify;
}
.custom-progress-bar {
  width: 80%;
  margin: 0 auto;
}
.model-dimensions {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  .dimension-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    span {
      opacity: 0.4;
    }
    .label {
      width: 100%;
    }
    .value {
      width: 100%;
      text-align: left;
    }
  }
}

@media screen and(min-width: 992px) {
  .dimensions-error {
    width: 40%;
  }
  .custom-progress-bar {
    width: 60%;
  }
  .model-dimensions {
    width: 40%;
    .dimension-box {
      .label {
        font-size: 18px;
      }
      .value {
        font-size: 18px;
      }
    }
  }
  .model-dimensions.with-errors {
    color: red;
    width: 60%;
    margin-top: 0%;
    span {
      font-size: 18px;
      opacity: 1;
    }
  }
}
