.primary {
  .process-content {
    @include media-breakpoint-up(md) {
      margin-top: 15%;
    }
  }
  .process-img {
    @include media-breakpoint-up(md) {
      margin-top: -10%;
    }
  }
  @include svg-background($primary, 100%, 75%, 0, 25%);
  align-items: center;
  justify-content: space-around;

  &--content {
    @include make-col-ready();
    @include make-col(8, 24);
    @include make-col-offset(2, 24);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: -2rem;
    color: $white;
  }

  &--image {
    @include make-col-ready();
    @include make-col(9, 24);
    @include make-col-offset(2, 24);

    img {
      @include img-fluid();
    }
  }
}

.top {
  @include make-col-ready();
  @include make-col(22, 24);
  @include make-col-offset(1, 24);
}

.process {
  &--card {
    @include make-col-ready();
    @include make-col(6, 24);
    border: none;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-down(md) {
      @include make-col(1, 1);
    }

    img.card-img-top {
      @include img-fluid();
      width: 50%;
      align-self: center;
    }
  }

  &--step {
    margin: ($spacer * 5) auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .step-content {
      @include make-col-ready();
      @include make-col(12, 24);
    }

    .step-number {
      @include make-col-ready();
      @include make-col(3, 12);
      @include make-col-offset(1, 12);

      h3 {
        font-size: 6rem;
        color: $primary;
      }
    }

    .step-text {
      @include make-col-ready();
      @include make-col(8, 12);
    }

    .step-img {
      @include make-col-ready();
      @include make-col(11, 24);
    }

    img {
      @include img-fluid();

      @include media-breakpoint-down(md) {
        max-height: 20rem;
        margin-bottom: 1rem;
      }
    }

    &__reverse {
      flex-direction: row-reverse;

      .step-number {
        @include make-col-offset(0, 12);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .primary {
    @include svg-background($primary, 100%, 66.66%, 0, 33.33%);

    &--content {
      @include make-col(6, 24);
    }

    &--image {
      @include make-col-offset(4, 24);
    }
  }

  .process {
    &--step {
      .step-number {
        h3 {
          font-size: 7em;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .primary {
    @include svg-background($primary, 100%, 100%, 0, 0);

    &--content {
      @include make-col(24, 24);
      @include make-col-offset(0, 24);

      order: 2;
      margin-top: 2rem;
    }

    &--image {
      @include make-col(24, 24);
      @include make-col-offset(0, 24);

      text-align: center;
    }
  }

  .process {
    &--step {
      .step-content {
        @include make-col(24, 24);
      }

      .step-img {
        @include make-col(24, 24);
        text-align: center;
      }

      .step-number {
        @include make-col-offset(0, 12);
        text-align: center;
      }

      &__reverse {
        .step-text {
          text-align: right;
        }

        .step-number {
          order: 2;
        }
      }
    }
  }
}
