#data {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#data td,
#data th {
  border: 1px solid #ddd;
  padding: 8px;
}

#data tr:nth-child(even) {
  background-color: #f2f2f2;
}

#data tr:hover {
  background-color: #ddd;
}

#data th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #7c6263;
  color: white;
}

.header-wrapper {
  position: relative;
  background-color: "#dadada";
  width: "100%";
}
