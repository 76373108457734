.carousel-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.carousel-wrapper {
  display: flex;
  width: 90%;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 10%;
}

.carousel-content.show-1 > * {
  width: 100%;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.button-wrapper {
  width: 96vw;
}
@media screen and(min-width: 992px) {
  .button-wrapper {
    height: 100%;
    position: absolute;
    width: 97vw;
  }
  .carousel-container {
    width: 100%;
  }
  .carousel-wrapper {
    width: 95%;
  }
  .right-arrow {
    right: 5%;
  }
}
