.launch-your-brand-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;

  .bigger-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: fit-content;
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: -105px;
      margin-left: 15px;
      width: fit-content;
      max-width: 42vw;
      font-size: 13.8px;

      .jewelry-pic {
        display: flex;
        justify-content: center;
        background: #f7eedd;

        img {
          width: 40vw;
        }
      }

      .jew-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        width: 28vw;
        min-height: 10vh;
        max-height: 12vh;
        .content {
          max-height: 100%;
          overflow-y: auto;
          min-width: fit-content;
          width: 38vw;
          .itemCnt {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .item-img {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .img-card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .brand-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 1.3rem;
    .brand-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: "Spartan", sans-serif;
      font-size: 38px;
      line-height: 43px;
      color: #7c6262;
      font-weight: 900;
      margin-bottom: 0.7rem;
    }

    .brand-homepage-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: "Spartan", sans-serif;
      font-size: 38px;
      line-height: 43px;
      color: #1b3966;
      font-weight: 900;
      margin-bottom: 0.7rem;
    }

    .brand-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: center;

      .start-design-button {
        background-color: #7c6262;
        color: white;
        margin: 30px 0;
        padding: 10px;
        border: none;
        text-align: center;
      }

      .apply-now-btn {
        background-color: #1b3966;
        color: white;
        margin: 30px 0;
        padding: 10px;
        border: none;
        text-align: center;
      }
    }
  }
}

@media screen and(min-width: 420px) and(max-width: 768px) {
  .marketing-container {
    .launch-your-brand-section {
      .bigger-card {
        .card {
          margin-bottom: -125px;
          margin-left: 16px;
          width: fit-content;
          max-width: 42vw;
          font-size: 15.8px;
          .jewelry-pic {
            flex: 1;
            img {
              width: 85%;
            }
          }

          .jew-content {
            padding: 10px;
            margin-bottom: 10px;
            min-width: fit-content;
            max-width: 35vw;
            min-height: fit-content;
            max-height: 12vh;
            .content {
              max-height: 100%;
              min-width: fit-content;
              width: 38vw;
              overflow-y: auto;
            }
          }
        }
      }

      .brand-section {
        .brand-title,
        .brand-homepage-title {
          font-family: "Spartan", sans-serif;
          font-size: 40px;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and(min-width: 768px) and(max-width: 992px) {
  .marketing-container {
    .launch-your-brand-section {
      margin-bottom: 4rem;
      margin-top: 0px;
      .bigger-card {
        .card {
          margin-top: 1vh;
          margin-right: -150px;
          width: fit-content;
          max-width: 42vw;
          height: fit-content;

          .jewelry-pic {
            img {
              width: 80%;
              border-radius: 12px;
            }
          }

          .jew-content {
            padding: 12px;
            margin-bottom: 10px;
            min-width: fit-content;
            max-width: 35vw;
            min-height: fit-content;
            max-height: 12vh;
            .content {
              max-height: 100%;
              min-width: fit-content;
              width: 38vw;
              overflow-y: auto;
            }
          }
        }
      }
      .brand-section {
        .brand-title,
        .brand-homepage-title {
          font-family: "Spartan", sans-serif;
          font-size: 38px;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and(min-width: 992px) {
  .launch-your-brand-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .brand-section {
      display: flex;
      flex-direction: column;
      width: 43%;

      .brand-title,
      .brand-description,
      .brand-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .brand-title,
      .brand-homepage-title {
        font-size: 46px;
        font-family: "Spartan", sans-serif;
        font-weight: 700;
        line-height: 3.8rem;
      }
    }

    .bigger-card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      width: 57%;

      .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5vh;
        margin-right: -150px;
        min-width: fit-content;
        max-width: 25vw;
        height: fit-content;
        font-size: 16px;
        max-height: 35vh;

        .jewelry-pic {
          border-radius: 12px;
          max-width: 16vw;

          img {
            width: 85%;
            border-radius: 12px;
          }
        }

        .jew-content {
          width: fit-content;
          min-height: 15vh;
          max-height: 20vh;
          max-width: 16vw;
          padding: 5px;
          .content {
            margin-bottom: 4px;
            padding: 15px;
            max-height: 100%;
            max-width: 100%;
            overflow-y: auto;
          }
        }
      }

      .img-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  .launch-your-brand-section.homepage {
    margin-top: 8rem;
    flex-direction: row-reverse;
  }
  .bigger-card.homepage {
    flex-direction: row-reverse;
    .card.homepage {
      margin-right: unset;
      margin-left: -100px;
    }
  }
  .brand-section.homepage {
    align-items: flex-end;
    .brand-content.homepage {
      width: 71%;
      text-align: right;
    }
  }
}
