.progress-bar-container {
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  text-align: center;
  justify-content: center;
  color: #7c6262;
  margin-bottom: 17px;
  margin-top: 26px;
}

@media screen and (min-width: 768px) {
  .progress-bar-container {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .progress-bar-container {
    margin-bottom: 26px;
  }
}
