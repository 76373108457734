.profile-form {
  //   width: 100%;

  margin: 100px auto;
  .step-title {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    // Add margin to align with step-title
    @include media-breakpoint-up(sm) {
      margin-left: 115px;
    }
  }
  .container {
    // For mobile devices on portrait
    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column; /* align children in rows */
      margin: 0 auto;
    }
  }
  .create-form-controls {
    justify-content: center;
    flex-wrap: nowrap;
    & > .col {
      flex-basis: unset;
      flex-grow: unset;
      max-width: unset;
      width: auto;
    }
  }
}
