$font-family-sans-serif: "Raleway", sans-serif;
$font-family-serif: "Raleway", sans-serif;
$font-family-monospace: "Raleway", sans-serif;
$font-family-base: "Raleway", sans-serif;

$gray: #9c9990;
$primary: #7c6262;
$secondary: #aea9a2;
$dark: #161615;

$theme-colors: (
  "primary": $primary,
  "dark": $dark,
  "secondary": $secondary,
  // "success": #44DD68,
    "success": $primary,
);

$landing-image: "../assets/images/landing_background.png";

// Compass can determine image dimensions automatically...
// But that  would add a dependency on ruby and compass.
// We can either depend on compass or update these whenever the logo images change, which should be rare.
$logo-width-white: 204px;
$logo-height-white: 27px;
$logo-height-factor-white: ($logo-height-white) / ($logo-width-white);

$logo-width-black: 1396px;
$logo-height-black: 181px;
$logo-height-factor-black: ($logo-height-black) / ($logo-width-black);

$logo-width-max: min($logo-width-black, $logo-width-white);

$logo-image-widths: (
  xs: (
    (10 / 24) * 100,
  ),
  sm: (
    (9 / 24) * 100,
  ),
  md: (
    (7 / 24) * 100,
  ),
  lg: (
    (5 / 24) * 100,
  ),
  xl: (
    (4 / 24) * 100,
  ),
);

$logo-image-width-unit: vw;
