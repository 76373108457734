.navbar-collapse{
  justify-content: flex-end;
}

.nav-item.profile {
  .dropdown-toggle {
    &::after {
      content: none;
    }
    p {
      margin: 0;
    }
  }

  .avatar-wrapper {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    .avatar-initials {
      position: absolute;
      background-color: rgb(167, 167, 167);
      font-weight: bold;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      color: white;
      font-size: 19px;
      text-align: center;
      justify-content: center;
    }
  }
}

@include media-breakpoint-up(md) {
  .nav-item {
    display: flex;
    align-items: center;
  }
}

.navbar-nav {
  @include media-breakpoint-down(xs) {
    margin-right: 2.5px;
  }

  margin-right: 10px;
}
