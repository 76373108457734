nav.bg-nav {
  height: 10vh;
  background-repeat: no-repeat;
  background-size: 100%;
}

.navbar.sit-on-top {
  position: absolute;
  top: 0;
  width: 100%;
}

// Override Bootstrap's `.navbar-expand-*` classes...
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      .navbar-brand,
      .navbar-toggler {
        z-index: 10;
      }

      @include media-breakpoint-down($breakpoint) {
        position: absolute;
        top: 0;
        width: 100vw;

        &.navbar-light {
          .navbar-collapse {
            background-color: rgba(255, 255, 255, 0.9);
          }
        }

        .navbar-collapse {
          $top: ($navbar-brand-padding-y * 2) + $navbar-padding-y +
            ($navbar-brand-font-size * $line-height-lg);
          $bottom: $navbar-padding-y;
          $left: $navbar-padding-x;
          $right: $navbar-padding-x;

          background-color: rgba(0, 0, 0, 0.75);

          padding: ($top + 0.5rem) $right $bottom $left;
          //   margin: (-1 * $top) (-1 * $right) (-1 * $bottom) (-1 * $left);

          .nav-item {
            width: fit-content;
          }
        }
      }
    }
  }
}

.navbar-brand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @if map-has-key($logo-image-widths, $breakpoint) {
      @include media-breakpoint-up($breakpoint) {
        img {
          max-width: 170px;
          max-height: 110px;
          object-fit: cover;
          margin-left: 10px;
          // max-width: $logo-width-max;
          // width: map-get($logo-image-widths, $breakpoint) +
          //   $logo-image-width-unit;
        }
        p {
          font-size: 8px;
          margin-bottom: -5px;
          text-align: end;
        }
      }
    }
  }
}

.icon-controller {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}

.order-lg-2 {
  margin-left: 0px !important;
}

.new-feat-icon {
  margin-right: 22px;
  .ann-nr {
    position: absolute;
    background: #e55353;
    color: white;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    margin-bottom: 30px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

.new-feat-icon:hover {
  cursor: pointer;
}
.modal-new-ft-main {
  overflow-y: scroll;
  max-height: 30rem;

  .updates {
    font-size: 17px;
    margin-bottom: 8px;
  }
  .modal-new-feature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    margin-bottom: 30px;
    padding: 4px;
    .new-feat-title {
      margin: auto;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .new-feat-image {
      img {
        max-width: 400px;
        max-height: 320px;
      }
    }
    .new-feat-desc {
      margin-top: 15px;
    }
  }
}

.new-feature-modal-dialog {
  width: 40rem;
}

@media screen and (max-width: 740px) {
  .modal-new-ft-main {
    width: fit-content;
    max-width: 21rem;

    .modal-new-feature {
      .new-feat-title {
        margin: auto;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .new-feat-image {
        img {
          width: fit-content;
          height: fit-content;
          max-width: 60vw;
          max-height: 50vh;
        }
      }
      .new-feat-desc {
        margin-top: 15px;
      }
    }
  }

  .new-feature-modal-dialog {
    width: 22rem;
  }
}
