/* FONTS */
@font-face {
  font-family: "Brandon Grotesque Light";
  font-style: normal;
  font-weight: normal;
  src: local("Brandon Grotesque Light"),
    url("../assets/fonts/brandon-grotesque-cufonfonts-webfont/Brandon_light.woff")
      format("woff");
}
@font-face {
  font-family: "Brandon Grotesque Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Brandon Grotesque Light"),
    url("../assets/fonts/brandon-grotesque-cufonfonts-webfont/Brandon_reg.woff")
      format("woff");
}
@font-face {
  font-family: "Brandon Grotesque Black";
  font-style: normal;
  font-weight: normal;
  src: local("Brandon Grotesque Black"),
    url("../assets/fonts/brandon-grotesque-cufonfonts-webfont/Brandon_bld.woff")
      format("woff");
}

/* Entire website uses Brandon Grotesque Regulars */
// TOOD: Might not have license for Brandon Grotesque
body {
  font-family: "Brandon Grotesque Regular";
}

/**
 * LANDING
 */
.landing {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-attachment: local;

  width: 100%;
  height: 100vh;

  color: $white;
  font-size: 16pt;

  display: flex;
  align-items: center;
  justify-content: center;
  background-position-x: 50%;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    align-items: center;
  }
}

.landing--call-to-action {
  @include make-col-ready();
  @include make-col(22, 24);
  @include make-col-offset(3, 24);

  @include media-breakpoint-down(md) {
    @include make-col(16, 24);
    @include make-col-offset(4, 24);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(22, 24);
    @include make-col-offset(2, 24);
  }
  flex: inherit !important;
}

/**
 * VALUE PROPOSITION
 */
.value {
  &--card {
    @include make-col-ready();
    @include make-col(1, 3);
    border: none;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-down(md) {
      @include make-col(1, 1);
    }

    img.card-img-top {
      @include img-fluid();
      width: unset;
      align-self: center;
    }
  }

  &--image {
    @include make-col-ready();
    @include make-col(1, 3);
    text-align: center;

    > img {
      @include img-fluid();
    }

    @include media-breakpoint-down(lg) {
      @include make-col(1, 1);
    }
  }

  &--content {
    @include make-col-ready();
    @include make-col(4, 6);
    @include make-col-offset(1, 6);
  }

  &--text {
    @include make-col-ready();
    @include make-col(2, 3);

    @include media-breakpoint-down(lg) {
      @include make-col(1, 1);
    }
  }
}

/**
 * FEATURED DESIGNER
 */
.designer {
  &--banner {
    @include make-col-ready();
    @include make-col(1, 1);
    text-align: center;

    img {
      @include img-fluid();
    }
  }

  &--info {
    @include make-col-ready();
    @include make-col(6, 24);
    @include make-col-offset(2, 24);
    margin-top: 100px;

    @include media-breakpoint-down(md) {
      @include make-col(1, 1);
      @include make-col-offset(0);
    }
  }

  &--example {
    @include make-col-ready();
    @include make-col(5, 24);
    margin-top: 100px;

    &:nth-of-type(2) {
      @include make-col-offset(3, 24);
    }

    img {
      @include img-fluid();
    }

    @include media-breakpoint-down(md) {
      @include make-col(1, 2);
      &:nth-of-type(2) {
        @include make-col-offset(0);
      }
    }

    @include media-breakpoint-down(sm) {
      @include make-col(1, 1);
    }
  }
}

/**
 * PINK SECTIONS
 */

.pink {
  min-height: 900px;
  @include svg-background($primary, 66.66%, 66.66%, 0, 0);

  &-md {
    color: $white;
    @include media-breakpoint-up(md) {
      @include svg-background($primary, 80%, 62%, 0, 0);
    }
    @include media-breakpoint-down(md) {
      @include svg-background($white, 100%, 100%, 0, 0);
    }
  }

  &-sm {
    color: white;
    @include media-breakpoint-down(md) {
      @include svg-background($primary, 100%, 100%, 0, 0);
    }
  }

  @include media-breakpoint-down(sm) {
  }

  &--hero {
    @include make-col-ready();
    @include make-col(8, 24);

    @include media-breakpoint-down(md) {
      @include make-col(12, 24);
      @include make-col-offset(6, 24);
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      @include make-col(1, 1);
      @include make-col-offset(0, 24);
    }

    img {
      @include img-fluid();

      @include media-breakpoint-down(md) {
        max-height: 20rem;
        margin-bottom: 1rem;
      }
    }
  }

  &--content {
    @include make-col-ready();
    @include make-col(12, 24);
    @include make-col-offset(1, 24);

    align-self: center;

    @include media-breakpoint-down(md) {
      @include make-col(1, 1);
      @include make-col-offset(0);
    }

    img {
      @include img-fluid();
    }
  }

  &--text {
    @include make-col-ready();
    @include make-col(6, 12);

    @include media-breakpoint-down(md) {
      @include make-col-offset(3, 12);
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      @include make-col(12, 12);
      @include make-col-offset(0, 12);
    }
  }

  &--image {
    @include make-col-ready();
    @include make-col(12, 12);

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.pink__reverse {
  transform: scaleX(-1);
  min-height: 900px;

  > * {
    transform: scaleX(-1);
  }

  .section--row {
    flex-direction: row-reverse;
  }

  .pink--hero {
    @include make-col-offset(1, 24);

    @include media-breakpoint-down(md) {
      @include make-col-offset(0, 24);
    }
  }

  .pink--content {
    @include make-col-offset(0, 24);
  }
}

/* Same across all device screen sizes */
.description {
  font-weight: bold;
}

/* Smartphones (portrait) ----------- */
.landing--button-group {
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    justify-content: center space-around;

    // Adds space between the button
    .button-padding {
      margin-top: 20px;
    }
    // Styling for text
    .description {
      display: table-cell;
      word-wrap: break-word;
      width: 100%;
      height: 100%;
      font-size: 3vw;
      line-height: 70%;
    }
    // Styling for upload button
    .upload-button {
      flex-grow: 0;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  // Styling for text
  .description {
    font-size: 4vw;
    line-height: 70%;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@include media-breakpoint-up(md) {
  // Styling for text
  .description {
    font-size: 4vw;
    line-height: 80%;
    margin: 15px 0;

    /* for ONLY portrait iPad */
    @include media-breakpoint-only(md) {
      white-space: nowrap;
    }
  }
}

/* big landscape tablets, laptops, and desktops */
@include media-breakpoint-up(lg) {
  // Styling for text
  .description {
    font-size: 2vw;
    line-height: 80%;
  }
}
