.homepage-container {
  margin-top: 70px;
  padding: 20px 20px;
  .homepage-hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .homepage-text {
      width: 100%;
      .homepage-hero-title {
        font-size: 45px;
        color: #7c6262;
        font-weight: bold;
        font-family: "Spartan", SANS-SERIF;
      }
      .homepage-hero-description {
        padding: 30px 0px;
        font-size: 18px;
        color: #7c6262;
      }
      .hero-button {
        background: #7c6262;
        color: #ffffff;
        border-radius: 10px;
        width: fit-content;
        padding: 6px;
        outline: none !important;
        border-color: #7c6262 !important;
      }
    }
    .homepage-hero-image {
      width: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
        padding: 25px;
      }
      .arrow-wrapper {
        .hero-info {
          width: 40%;
          margin-top: -104px;
          max-width: 105px;
          float: right;
          .hero-info-text {
            color: black;
          }
          .hero-names {
            color: #b07807;
          }
        }
      }
    }
  }

  .homepage-brands {
    .brands-text {
      font-size: 14px;
      padding: 20px 0px;
      font-weight: bold;
    }
    .brand-logos {
      display: flex;
      flex-direction: row;
      padding: 20px 0px;
      height: 70px;
      .brand-logo {
        margin-right: 10px;
        text-align: center;
        img {
          max-height: 150px;
          //   max-width: 10;
        }
      }
    }
  }
  .homepage-roadmap {
    padding: 20px 0px;
    background-image: url("../assets/images/homepage-works-mobile.png");
    background-repeat: no-repeat;
    background-size: fill;
    .homepage-roadmap-title-wrapper {
      width: 100%;
      .homepage-roadmap-title {
        text-align: center;
        font-size: 30px;
        color: #1b3966;
        font-weight: bold;
        font-family: "Spartan", SANS-SERIF;
      }
      .homepage-roadmap-description {
        display: none;
      }
    }
    .homepage-roadmap-wrapper {
      .homepage-roadmap-image {
        img {
          max-width: 82vw;
          padding: 40px;
        }
      }
      .homepage-roadmap-info {
        .milestone-steps {
          font-size: 18px;
          color: #1b3966;
          padding: 5px 0px;
        }
        .milestone-title {
          font-size: 30px;
          color: #000000;
          font-weight: bold;
          font-family: "Spartan", SANS-SERIF;
          padding: 5px 0px;
        }
        .milestone-description {
          font-size: 17px;
          color: #000000;
          padding: 5px 0px;
        }
        .milestone-buttons {
          display: flex;
          .milestone-button {
            padding: 5px 0px;
            width: 150px;
            margin-top: 10%;
            .btn-primary {
              background: #1b3966;
              color: #ffffff;
              border-radius: 5px;
              border: none;
              outline: none;
              padding: 10px 20px;
            }
          }
          .milestone-button-learn-more {
            margin-top: 10%;
            padding: 5px 0px;
            display: block;
            .btn-primary {
              background: #ffffff;
              color: #1b3966;
              border-radius: 5px;
              border: 1px solid #1b3966;
              outline: none;
              padding: 10px 20px;
            }
          }
        }
      }
    }
    .milestone-stepper {
      color: #1b3966;
      text-align: center;
      font-size: 30px;
      .btn-primary {
        color: #fff;
        background-color: #1b3966;
        border-color: #1b3966;
        margin: 12px;
        /* font-size: 23px; */
        border-radius: 50%;
      }
    }
  }
  .featured-designs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    margin-bottom: 2rem;
    margin-top: 1rem;
    .title-section {
      display: flex;
      flex-direction: column;
      .design-title {
        font-size: 40px;
        color: #b07807;
        font-weight: 700;
        font-family: "Spartan", SANS-SERIF;
      }
      .design-description {
        font-size: 17px;
        color: #231f20;
      }
    }
    .designs-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1rem;
      height: 45vh;
      margin-top: 1rem;
      .designer-card {
        text-align: center;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        padding: 35px;
        margin-top: 30px;
        margin-right: 5px;
        height: fit-content;
        min-height: 43vh;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        .photo-section {
          margin-top: 3.5rem;
          img {
            height: auto;
            width: 80%;
          }
        }
        .design-title {
          font-size: 17px;
          text-align: center;
        }
        .designer-name {
          font-size: 17px;
          color: #b07807;
          text-align: center;
        }
      }
    }
  }
  .partner-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .partner-info-section {
      width: 100%;
      background-color: #7c6262;
      padding: 20px;
      margin-top: 20px;
      .partner-title {
        font-size: 56px;
        font-weight: 700;
        color: #ffffff;
        width: 60%;
        font-family: "Spartan", SANS-SERIF;
      }
      .partner-description {
        font-size: 18px;
        color: #ffffff;
      }
      .partner-button {
        padding: 5px 0px;
        width: 150px;
        .btn-primary {
          background: #1b3966;
          color: #ffffff;
          border-radius: 5px;
          border: none;
          outline: none;
          padding: 10px 20px;
          margin-top: 20px;
        }
      }
    }
    .partner-photo-section {
      width: 100%;
      img {
        max-width: 100%;
      }
    }
  }
  .faq-section {
    padding: 40px 0px;

    .faq-title {
      font-size: 34px;
      font-weight: bold;
      padding: 20px 0px;
    }
    .links {
      .single-link {
        border-bottom: 1px solid rgba(18, 22, 28, 0.3);
        padding: 20px 0px;
        .link-click {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .link-title {
            font-size: 24px;
          }
          //   i {
          //     color: black;
          //   }
        }
        .collapsee {
          .link-content {
            font-size: 17px;
            padding: 20px 0px;
          }
        }
      }
      .single-link:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and(min-width: 768px) {
  .homepage-container {
    .homepage-roadmap {
      background-image: url("../assets/images/homepage-work-background.png");
      background-size: 40% 90%;
      background-position: 100%;
    }
    .homepage-hero-container {
      width: 80%;
      margin: 0 auto;
    }
    .homepage-brands {
      //      width: 80%;
      margin: 0 auto;
    }
    .homepage-roadmap-wrapper {
      .homepage-roadmap-image {
        text-align: center;
      }
      .homepage-roadmap-info {
        text-align: center;
        .milestone-description {
          width: 60%;
          margin: 0 auto;
        }
        .milestone-button {
          margin: 0 auto;
        }
      }
    }
    // .icons-container {
    //   margin: 0px 50px;
    // }
    .featured-designs {
      //margin: 50px;
      padding: 220px 0px;
    }
    .partner-section {
      display: flex;
      flex-direction: row;
      background-color: #7c6262;
      .partner-info-section {
        //padding: 230px 130px;
        margin-top: 100px;
        margin: 0;
      }
      .partner-photo-section {
        img {
          height: 100%;
        }
      }
    }
    .faq-section {
      margin: 50px;
    }
  }
}

@media screen and(min-width: 992px) {
  .homepage-container {
    .homepage-roadmap {
      background-image: url("../assets/images/homepage-work-background.png");
      background-size: 40% 90%;
      background-position: 100%;
      padding: 100px;
      .homepage-roadmap-title-wrapper {
        .carousel {
          .slide {
            height: 85vh;
          }
        }
        .homepage-roadmap-title {
          text-align: center;
          font-size: 54px;
          color: #1b3966;
          font-weight: 700;
          line-height: 83px;
        }
        .homepage-roadmap-description {
          display: block;
          text-align: center;
          margin: 0 auto;
          color: #231f20;
          padding: 20px 0px;
          width: 30%;
          font-size: 18px;
        }
      }
    }
    .homepage-hero-container {
      flex-direction: column;
      padding: 20px 0px;
      width: 80%;
      .homepage-hero-main {
        display: flex;
        .homepage-text {
          padding: 35px 0px;
          .homepage-hero-title {
            font-size: 72px;
            font-weight: 700;
            font-family: "Spartan", SANS-SERIF;
            line-height: 83px;
          }
          .homepage-hero-description {
            width: 80%;
          }
        }
        .homepage-hero-image {
          .arrow-wrapper {
            .hero-info {
              margin-top: -135px;
              width: 21%;
              max-width: 21%;
            }
          }
        }
      }
      .homepage-brands {
        width: 100%;
        .brands-text {
          padding: 0;
        }
        .brand-logos {
          padding: 12px 0px;
        }
      }
    }
    .homepage-roadmap-wrapper {
      display: flex;
      flex-direction: row-reverse;
      .homepage-roadmap-image {
        width: 50%;
        img {
          max-width: 39vw !important;
        }
      }
      .homepage-roadmap-info {
        padding: 110px;
        width: 50%;
        text-align: left;
        .milestone-title {
          font-size: 36px;
          font-weight: 700;
        }
        .milestone-description {
          width: 100%;
          text-align: left;
        }
        .milestone-buttons {
          .milestone-button {
            margin: 0;
          }
          .milestone-button-learn-more {
            width: 150px;
            display: block;
            .btn-primary {
              background: #ffffff;
              color: #1b3966;
              border-radius: 5px;
              border: 1px solid #1b3966;
              outline: none;
              padding: 10px 20px;
            }
          }
        }
      }
    }
    // .icons-container {
    //   margin: 0px 50px;
    // }
  }
  .featured-designs {
    //margin: 50px;
    padding: 20px 0px;
    margin-top: 0px;
    .title-section {
      margin-bottom: 2rem;
      text-align: center;
      .design-title {
        span {
          font-size: 56px;
        }
      }
      .design-description {
        width: 40%;
        margin: 0 auto;
      }
    }
    .designs-section {
      padding: 20px 0px;
      overflow: hidden;
      .designer-card {
        margin-right: 20px;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
      }
    }
  }

  .partner-section {
    display: flex;
    flex-direction: row;
    background-color: #7c6262;
    .partner-info-section {
      //padding: 230px 130px;
      width: 65%;
      margin-top: 100px;
      margin: 0;
      .partner-title {
        margin-top: 120px;
        width: 80%;
        margin-left: 10%;
      }
      .partner-description {
        width: 50%;
        margin-left: 10%;
      }
      .partner-button {
        width: 80%;
        margin-left: 10%;
      }
    }
    .partner-photo-section {
      padding: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  .faq-section {
    .faq-title {
      font-size: 56px;
      color: #b07807;
      font-weight: 700;
      .single-link {
        .link-content {
          .link-title {
            color: #b07807;
          }
          //   i{
          //     color: #b07807;
          //   }
        }
      }
    }
    .links {
      width: 90%;
      margin: 0 auto;
      .single-link {
        // .link-content {
        //   color: #b07807;
        .link-title {
          color: #b07807;
        }
        .fa-minus {
          color: #b07807;
        }
        .link-title-collapsed {
          color: black;
          font-size: 24px;
        }

        .icon-collapsed {
          color: black;
        }
        // }
        // .link-click {
        //   color: #b07807;
        // //   i{
        // //       color: #b07807;
        // //   }
        // }
        // .link-click-collapsed {
        //   color: red;
        //   i {
        //     color: #b07807;
        //   }
        // }
        // .collapsed{
        //     color: black;
        // }
      }
    }
  }
}
