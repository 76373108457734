.cart-item {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 25px;
  .row {
    // Medium (tablets, less than 992px)
    @include media-breakpoint-between(md, lg) {
      height: 5vw;
    }

    // Extra small devices (portrait phones, less than 576px)
    @include media-breakpoint-down(xs) {
      height: 10vw;
    }

    //maybe FIXME?: iphone 5/se portrait - overlapping price and X symbol

    margin-bottom: 5px;
    justify-content: space-between;
    button.btn-outline-dark {
      border: none;
      &:hover {
        color: $primary;
        background-color: transparent;
      }
    }
  }
}
.cart {
  position: relative;
  width: fit-content;

  &.opened {
    .nav-link {
      background-color: $primary;
      color: white;

      // For device screens size up to medium
      @include media-breakpoint-down(md) {
        padding-left: 3vw;
        padding-right: 3vw;
      }
      // For tablets, desktop, and up
      @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .badge {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .cart-list {
    min-height: calc(60vh - 0px);
    min-width: 33vw;
    overflow: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px black solid;
    display: flex;
    flex-direction: column;

    // Large devices (desktops, less than 1200px)
    @include media-breakpoint-up(lg) {
      width: 28vw;
    }

    // For iPad + iPad Pro (768px->1200px)
    @include media-breakpoint-between(md, lg) {
      width: 40vw;
    }

    // Small to md devices (576px->768px)
    @include media-breakpoint-between(sm, md) {
      width: 50vw;
    }

    // Extra small devices (portrait phones, less than 576px)
    @include media-breakpoint-down(xs) {
      width: 90vw;
    }

    // FIXME: iPhone 5/SE is janky and doesnt listen
    @media screen and (max-width: 568px) {
      width: 30vw;
    }
    // @include media-breakpoint-only(xs) {
    //     width: 30vw;
    // }

    input[type="checkbox"]:checked {
      background-color: #7c6262;
      border-color: #7c6262;
      color: white;
    }

    .terms {
      .form-check-label {
        color: #7c6363;
        a {
          color: #7c6262;
        }
      }
    }

    .terms:checked {
      color: #7c6262;
    }
    form {
      width: 100%;
    }
    .states {
      width: 40%;
    }

    & > div {
      width: 75%;
      margin: 0 auto;
    }
    .title {
      margin: 40px 0;
      h3 {
        color: $primary;
        text-align: center;
      }
    }
    ul {
      padding: 0;
      margin-bottom: 40px;
      li {
        list-style-type: none;
        margin: 15px 0;
        p {
          margin: 0;
        }
      }
    }
    .dropdown {
      width: 100%;
      button {
        width: 110px;
        height: 45px;
        font-size: 16pt;
        border-radius: 0px;
      }
    }
    .dropdown-toggle::after {
      font-size: 1rem;
    }
  }
  .price {
    text-align: right;
  }
  .checkout {
    width: 100%;
    border-radius: 0;
    font-size: 16pt; //todo
    margin: 20px 0;
    background-color: #7c6262;
    color: white;
    border-color: #7c6262;
  }

  .checkout:hover {
    background-color: #997878;
    border-color: #997878;
  }
}

.login {
  .rebrand-login-button {
    background: transparent;
    color: #7c6262;
    border-color: #7c6262;
  }
  .rebrand-signup-button {
    background: #7c6262;
    color: #fff !important;
    border-color: #7c6262;
  }

  .rebrand-login-button:hover {
    background-color: #7c6262;
    color: white !important;
    border-color: #7c6262;
  }

  .rebrand-signup-button:hover {
    background-color: #fff;
    color: #7c6262 !important;
    border-color: #7c6262 !important;
  }
}

@media screen and (min-width: 992px) {
  .login {
    .rebrand-login-button {
      width: 80px;
      margin-right: 5px;
    }
    .rebrand-signup-button {
      width: 80px;
      margin-left: 5px;
    }
  }
}
