.price-holder {
  width: 100%;
  & > .col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .deliveryDate {
    width: 20%;
    text-align: center;
  }
  .price-widget {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    align-items: center;
    justify-content: space-around;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    padding: 10px 0;
    width: fit-content;
    &.row {
      & > .col {
        flex-basis: unset;
        flex-grow: unset;
        max-width: unset;
        width: unset;
      }
    }
    @include media-breakpoint-up(md) {
      padding: 0;
      width: 45%;
      // margin-left: 10%;
      // margin-top: -21%;
      margin-bottom: 30px;
      &.row {
        & > .col {
          margin: 45px 0;
        }
        .form-group {
          margin-bottom: 0;
        }
      }
    }

    .main-calc {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .revenue,
      .profit {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size: 19px;
        margin-bottom: 8px;
      }
    }

    .switches {
      margin-bottom: 2rem;
      .toggle-switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 25px;
        margin-left: 50px;
      }
      .toggle-switch input[type="checkbox"] {
        display: none;
      }
      .toggle-switch .switch {
        position: absolute;
        cursor: pointer;
        background-color: #ccc;
        border-radius: 25px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 50px;
        transition: background-color 0.2s ease;
      }
      .toggle-switch .switch::before {
        position: absolute;
        content: "";
        left: 2px;
        top: 2px;
        width: 21px;
        height: 21px;
        background-color: #aaa;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
      .toggle-switch input[type="checkbox"]:checked + .switch::before {
        transform: translateX(25px);
        background-color: #aaa;
      }
      .toggle-switch input[type="checkbox"]:checked + .switch {
        background-color: #7c6262;
      }
    }
  }
}

.delivery-date {
  // Portrait devices
  // @include media-breakpoint-down(xs) {
  // text-align: center;
  text-align: center;
  // }
}
